import { Avatar, Card, List, Space, Button, Spin } from "antd";
import { Divider, message } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DateSelected,
  emptyDateofSingleVehicleLog,
  fetchSingelVehicleLogs,
} from "../store/reducers/vehicleReducer";
import { PlayCircleOutlined, StopOutlined } from "@ant-design/icons";
import moment from "moment";

const { Meta } = Card;

function Vehicleloglist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let accesstoken = useSelector((state) => state?.persist?.auth?.token);
  let activeVehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );
  let SingleVehicleLogs = useSelector(
    (state) => state?.persist?.vehicle?.SingleVehicleLogs,
  );
  let SingleVehicleLogsDate = useSelector(
    (state) => state?.persist?.vehicle?.SingleVehicleLogsDate,
  );
  let isDateSelected = useSelector((state) => state?.persist?.vehicle);
  // const [Logsdata, setLogsdata] = useState([]);
  const [dateChanged, setDateChanged] = useState(true);

  var AllLogsdata = [];
  var Logsdata = [];

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) => {
      AllLogsdata?.push(data);
    });
  }

  const dateIsChanged = useCallback(() => {
    if (dateChanged) {
      setDateChanged(false);
    }
  }, [dateChanged]);
  if (SingleVehicleLogsDate !== undefined && SingleVehicleLogsDate !== null) {
    dateIsChanged();
    SingleVehicleLogs?.map((data) => {
      if (
        moment(data?.createdAt).format("DD-MM-YYYY") ===
        SingleVehicleLogsDate?.label
      ) {
        Logsdata?.push(data);
      }
    });
  }

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    setDateChanged(true);
    dispatch(emptyDateofSingleVehicleLog());
    const payload = {
      activeVehicle: activeVehicle?.id,
      token: accesstoken,
    };
    setLoadingSpin(true);
    dispatch(fetchSingelVehicleLogs(payload)).then((res) => {
      setLoadingSpin(false);

      if (res?.payload?.data?.error) {
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
    });
  }, []);

  return (
    <div>
      <Spin spinning={loadingSpin} size="large">
        <List
          dataSource={dateChanged ? AllLogsdata : Logsdata}
          // dataSource={Logsdata}
          // dataSource={SingleVehicleLogs}
          style={{
            overflowY: "scroll",
            maxHeight: "335px",
            position: "relative",
          }}
          renderItem={(item) => (
            <>
              {item?.type === "work" ? (
                <Card
                  style={{
                    margin: 5,
                    borderLeftWidth: "10px",
                    borderLeftColor: "#0ACF83",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: 500,
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>{item?.driverName}</p>
                      <p>{/* #<span>{item?.vehiclePlateNumber}</span> */}</p>
                    </div>
                    <div>
                      <p>
                        <Space>
                          <span style={{ color: "#0ACF83" }}>
                            <PlayCircleOutlined /> Started Working
                          </span>
                          {item?.start === null ? (
                            <span> Ongoing</span>
                          ) : (
                            <span>
                              {" "}
                              {moment(item?.start).format("DD-MM-YYYY hh:mm A")}
                            </span>
                          )}
                        </Space>
                      </p>
                      <p>{item?.start_coord?.formatted_address}</p>
                      <br></br>
                      <p>
                        <Space>
                          <span style={{ color: "#FF0000" }}>
                            {" "}
                            <StopOutlined /> Stopped Working{" "}
                          </span>
                          {item?.stop === null ? (
                            <span> Ongoing</span>
                          ) : (
                            <span>
                              {" "}
                              {moment(item?.stop).format("DD-MM-YYYY hh:mm A")}
                            </span>
                          )}
                        </Space>
                      </p>
                      <p>{item?.stop_coord?.formatted_address}</p>
                    </div>
                  </div>
                </Card>
              ) : (
                <Card
                  style={{
                    margin: 5,
                    borderLeftWidth: "10px",
                    borderLeftColor: "#4facf7",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: 500,
                      }}
                    >
                      <p style={{ fontWeight: 600 }}>{item?.driverName}</p>
                      <p>{/* #<span>{item?.vehiclePlateNumber}</span> */}</p>
                    </div>
                    <div>
                      <p>
                        <Space>
                          <span style={{ color: "#0ACF83" }}>
                            {" "}
                            <PlayCircleOutlined /> Started Resting{" "}
                          </span>
                          {item?.start === null ? (
                            <span> Ongoing</span>
                          ) : (
                            <span>
                              {" "}
                              {moment(item?.start).format("DD-MM-YYYY hh:mm A")}
                            </span>
                          )}
                        </Space>
                      </p>
                      <p>{item?.start_coord?.formatted_address}</p>
                      <br></br>
                      <p>
                        <Space>
                          <span style={{ color: "#FF0000" }}>
                            {" "}
                            <StopOutlined /> Stopped Resting{" "}
                          </span>
                          {item?.stop === null ? (
                            <span> Ongoing</span>
                          ) : (
                            <span>
                              {" "}
                              {moment(item?.stop).format("DD-MM-YYYY hh:mm A")}
                            </span>
                          )}
                        </Space>
                      </p>
                      <p>{item?.stop_coord?.formatted_address}</p>
                    </div>
                  </div>
                </Card>
              )}
            </>
          )}
        />
      </Spin>
    </div>
  );
}

export default Vehicleloglist;

