import { CloudUploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileUpload = ({ file, setFile }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="main-upload-container">
      <div className="upload-container" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-content">
          <div className="upload-icon">
            {!file ? <CloudUploadOutlined /> : <FileExcelOutlined />}
          </div>
          <div className="upload-text">
            {!file ? (
              isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>
                  Drag & Drop to Upload File
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                  <Button
                    style={{
                      borderRadius: "5px",
                    }}
                    type="primary"
                  >
                    Browse File
                  </Button>
                </p>
              )
            ) : (
              <>
                <p>{file.name}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

