import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Result,
  Modal,
  message,
} from "antd";
import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicle,
  addVehicleToList,
  fetchAllVehiclesAPI,
  fetchVehicleMake,
  fetchVehicleModels,
  fetchVehicleTypes,
} from "../store/reducers/vehicleReducer";
import { addVehicleAPIHelper } from "../helpers/vehicleAPI";
import { useState } from "react";
import { useEffect } from "react";
import { renewAccessToken } from "../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const Addvehicleform = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accesstoken = useSelector((state) => state?.persist?.auth?.token);
  let token = useSelector((state) => state?.persist?.auth?.token);
  let refreshToken = useSelector((state) => state?.persist?.auth?.refreshToken);
  const [messageApi, contextHolder] = message.useMessage();
  console.log("auth", accesstoken);
  console.log("refreshToken", refreshToken);
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");

  const [form] = Form.useForm();

  let vehiclesstate = useSelector((state) => state?.persist?.vehicle);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  useEffect(() => {
    dispatch(fetchVehicleMake());
    dispatch(fetchVehicleTypes());
  }, []);

  const onFinish = (values) => {
    const payload = {
      accessToken: accesstoken,
      vehiclemakeID: makeValue,
      vehiclemodelID: modelValue,
      vehicletypeID: values?.Type,
      plateNumber: values?.PlateNo,
      initialOdometer: values?.CurrentOdometer,
    };
    dispatch(addVehicle(payload)).then((response) => {
      if (response.payload.data.success === true) {
        messageApi.open({
          type: "success",
          content: response.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchAllVehiclesAPI(token));
        props.onAddVehicleHandler("list-of-vehicles");
        onReset();
      }
      if (response.payload.data.success === false) {
        if (response.payload.data.error) {
          messageApi.open({
            type: "warning",
            content: response.payload.data.error,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
        if (response.payload.data.message) {
          messageApi.open({
            type: "warning",
            content: response.payload.data.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
      }
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const makeChanged = (e, index) => {
    setMakeValue(index.selected);
    const vehiclemakeID = index.selected;
    dispatch(fetchVehicleModels(vehiclemakeID));
  };

  const modelChanged = (e, index) => {
    setModelValue(index.selected);
  };
  const styles = {
    text_input: {
      textAlign: "left",
    },
  };
  return (
    <Form
      {...layout}
      form={form}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      // style={styles.text_input}
    >
      {contextHolder}
      <Form.Item
        name="Make"
        label="Make"
        style={styles.text_input}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          onChange={makeChanged}
          showSearch
          placeholder="Select Make"
          allowClear
        >
          {vehiclesstate?.VehiclesMake?.map((e) => {
            return (
              <Option selected={e?.id} value={e?.name}>
                {e?.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="Model"
        label="Model"
        style={styles.text_input}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          onChange={modelChanged}
          showSearch
          placeholder="Select Model"
          allowClear
        >
          {vehiclesstate?.VehiclesModel?.map((e) => {
            return (
              <Option selected={e?.id} value={e?.name}>
                {e?.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="Type"
        label="Type"
        style={styles.text_input}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Select Type" allowClear>
          {vehiclesstate?.VehicleType?.map((e) => {
            return <Option value={e?.id}>{e?.name}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="PlateNo"
        label="Plate No"
        style={styles.text_input}
        rules={[
          {
            required: true,
          },
          {
            required: false,
            pattern: new RegExp(/^[a-zA-Z0-9-]{0,14}$/),
            message: "Enter a valid Plate No",
          },
        ]}
      >
        <Input
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          placeholder="Plate No"
        />
      </Form.Item>
      <Form.Item
        name="CurrentOdometer"
        label="Current Odometer"
        style={styles.text_input}
        rules={[
          {
            required: false,
            pattern: new RegExp(/^[0-9]{0,7}$/),
            message: "Enter a valid Odometer",
          },
        ]}
      >
        <Input suffix="KM" placeholder="Current Odometer" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          lg: {
            offset: 8,
          },
          md: {
            offset: 8,
          },
          sm: {
            offset: 8,
          },
        }}
      >
        <Button id="addVehicleButton" type="primary" htmlType="submit" block>
          Add Vehicle
        </Button>
        <p id="success_msg"></p>
      </Form.Item>
      {/* <Model /> */}
    </Form>
  );
};
export default Addvehicleform;

