import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Result,
  Modal,
  Row,
} from "antd";
import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicle,
  updateVehilce,
  fetchVehicleMake,
  fetchVehicleModels,
  fetchVehicleTypes,
  deleteVehilce,
  fetchAllVehiclesAPI,
  selectVehicle,
} from "../store/reducers/vehicleReducer";
import { addVehicleAPIHelper } from "../helpers/vehicleAPI";
import { useState } from "react";
import { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import Title from "antd/lib/skeleton/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const Editvehicleform = (props) => {
  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [activeVehicleDetails, setActiveVehicleDetails] = useState();
  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const dispatch = useDispatch();
  let token = useSelector((state) => state?.persist?.auth?.token);
  console.log("auth", token);
  let activevehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );
  console.log("Active vehicle", activevehicle);
  let ListVehicle = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList,
  );
  console.log("vehicleList", ListVehicle);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchAllVehiclesAPI(token));
    ListVehicle?.map((e) => {
      if (e?.id === activevehicle?.id) {
        dispatch(selectVehicle(e));
        form.setFieldsValue({
          Make: e?.make?.id,
          Model: e?.model?.id,
          Type: e?.type?.id,
          PlateNo: e?.plateNumber,
          CurrentOdometer: e?.initialOdometer,
        });
      }
    });
  }, []);

  let vehiclesstate = useSelector((state) => state?.persist?.vehicle);

  const onFinish = (values) => {
    const payload = {
      accessToken: token,
      activeVehicle: activevehicle?.id,
      vehiclemakeID: values?.Make,
      vehiclemodelID: values?.Model,
      vehicletypeID: values?.Type,
      plateNumber: values?.PlateNo,
      odometer: values?.CurrentOdometer,
    };
    dispatch(updateVehilce(payload));
    console.log("Update Vehicle Data", payload);
    // dispatch(selectVehicle(payload));
    showEditModal();
  };

  const makeChanged = (e) => {
    const vehiclemakeID = e;
    console.log("vehiclemakeID", vehiclemakeID);
    dispatch(fetchVehicleModels(vehiclemakeID));
    form.setFieldsValue({
      Model: " ",
    });
  };

  const EditModel = () => {
    const navigate = useNavigate();
    const handleCancel = () => {
      setIsEditModalOpen(false);
    };

    const navigateListofVehicles = () => {
      navigate(`/app/vehicles`);
    };
    const navigateUpdateVehicle = () => {
      navigate(`/app/vehicles/vehicledetails/${activevehicle?.id}`);
    };

    return (
      <>
        <Modal style={{ padding: 0 }} open={isEditModalOpen} footer={null}>
          <Result
            style={{ padding: 0 }}
            status="success"
            title="Vehicle Updated Successfully!"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={navigateUpdateVehicle}
              >
                Show Updated Vehicle
              </Button>,
              <Button key="success" onClick={navigateListofVehicles}>
                View List of Vehicles
              </Button>,
            ]}
          />
        </Modal>
      </>
    );
  };

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  const backToPrev = () => {
    navigate(`/app/vehicles`);
  };

  return (
    <div>
      <Row>
        <ArrowLeftOutlined
          style={{ width: 60, fontSize: 20, paddingTop: 20, paddingBottom: 20 }}
          onClick={() => backToPrev()}
        />
      </Row>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            lg: {
              offset: 8,
            },
            md: {
              offset: 8,
            },
            sm: {
              offset: 8,
            },
          }}
        >
          <h2>Edit Vehicle</h2>
        </Form.Item>
        <Form.Item
          name="Make"
          label="Make"
          style={styles.text_input}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={makeChanged}
            placeholder={activevehicle?.make}
            allowClear
          >
            {vehiclesstate?.VehiclesMake?.map((e) => {
              return <Option value={e?.id}>{e?.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="Model"
          label="Model"
          style={styles.text_input}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={activevehicle?.model} allowClear>
            {vehiclesstate?.VehiclesModel?.map((e) => {
              return <Option value={e?.id}>{e?.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="Type"
          label="Type"
          style={styles.text_input}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={activevehicle?.type} allowClear>
            {vehiclesstate?.VehicleType?.map((e) => {
              return <Option value={e?.id}>{e?.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="PlateNo"
          label="Plate No"
          style={styles.text_input}
          rules={[
            {
              required: true,
            },
            {
              required: false,
              pattern: new RegExp(/^[a-zA-Z0-9-]{0,20}$/),
              message: "Enter a valid Plate No",
            },
          ]}
        >
          <Input
            onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            placeholder={activevehicle?.plateNumber}
          />
        </Form.Item>
        <Form.Item
          name="CurrentOdometer"
          label="Current Odometer"
          style={styles.text_input}
          rules={[
            {
              required: false,
              pattern: new RegExp(/^[0-9]{0,6}$/),
              message: "Enter a valid Odometer",
            },
          ]}
        >
          <Input suffix="KM" placeholder={activevehicle?.initialOdometer} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            lg: {
              offset: 8,
            },
            md: {
              offset: 8,
            },
            sm: {
              offset: 8,
            },
          }}
        >
          <Button onClick={EditModel} type="primary" htmlType="submit">
            Edit Vehicle
          </Button>
        </Form.Item>
        <EditModel />
      </Form>
    </div>
  );
};
export default Editvehicleform;

