import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Spin, message } from "antd";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import sampleData from "../assets/sampleData.xlsx";
import FileUpload from "../components/FileUpload";
import {
  fetchAllVehiclesAPI,
  vehicleBulkUpload,
} from "../store/reducers/vehicleReducer";

const VehicleBulkImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { token } = useSelector((state) => state.persist.auth);

  useEffect(() => {
    setOpen(!!file);
  }, [file]);

  const handleBulkUpload = async () => {
    setOpen(false);
    setLoading(true);
    const payload = { file, accesstoken: token };

    try {
      const res = await dispatch(vehicleBulkUpload(payload)).unwrap();
      setFile(null);
      const isSuccess = res?.payload?.data?.success || res?.data?.success;
      const msgContent = res?.payload?.data?.message || res?.data?.message;
      if (isSuccess) {
        dispatch(fetchAllVehiclesAPI(token));
        messageApi.success({
          content: msgContent,
          className: "custom-class",
          style: { marginTop: "10vh" },
        });
        setTimeout(() => {
          navigate("/app/vehicles");
        }, 1500);
      } else {
        messageApi.error({
          content: msgContent,
          className: "custom-class",
          style: { marginTop: "10vh" },
        });
      }
    } catch (error) {
      console.error("API error:", error);
      messageApi.error({
        content: "API error occurred",
        className: "custom-class",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  const SelectedFileModal = () => (
    <Modal
      open={open}
      centered
      title="Selected File"
      onOk={handleBulkUpload}
      onCancel={() => setFile(null)}
      footer={[
        <Button key="reset" onClick={() => setFile(null)}>
          Reset
        </Button>,
        <Button key="proceed" type="primary" onClick={handleBulkUpload}>
          Proceed
        </Button>,
      ]}
    >
      <div className="upload-content">
        <FileExcelOutlined className="upload-icon" />
        <p style={{ marginTop: 10, fontSize: 18 }}>{file?.name}</p>
      </div>
    </Modal>
  );

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Row>
        <ArrowLeftOutlined
          style={{ width: 60, fontSize: 20, paddingTop: 20, paddingBottom: 20 }}
          onClick={() => navigate("/app/vehicles")}
        />
        <p style={{ fontSize: 20, top: 12, position: "relative" }}>
          Vehicle Bulk Upload
        </p>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 30,
          fontSize: 16,
          paddingBottom: 20,
        }}
      >
        <p>
          Here is the sample file for the vehicle details, please download and
          fill the details and upload the file below.
        </p>
        <div>
          <Button type="primary" style={{ width: 200 }}>
            <a href={sampleData} download="sampleFile.xlsx">
              Download Sample File{" "}
              <DownloadOutlined style={{ fontSize: 15, margin: "0 5px" }} />
            </a>
          </Button>
        </div>
      </div>
      <div style={{ paddingTop: 25 }}>
        {contextHolder}
        <Spin spinning={loading} size="large">
          <FileUpload
            file={file}
            setFile={setFile}
            handleBulkUpload={handleBulkUpload}
          />
          <SelectedFileModal />
        </Spin>
      </div>
    </div>
  );
};

export default VehicleBulkImport;

