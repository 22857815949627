import { useNavigate } from "react-router-dom";
import { Layout, Modal, Button } from "antd";
import React, { useState } from "react";

import Addvehiclesform from "../pages/addvehicleform";

import SubHeader from "../components/subheader";
import { useDispatch } from "react-redux";
import { selectVehicle } from "../store/reducers/vehicleReducer";
import VehicleScrollList from "../components/VehiclesScrollList";
import MotoCheck from "../pages/motocheck";
import AddVehicleWithNZTA from "../pages/AddVehicleWithNZTA";
const { Header } = Layout;

function VehiclesScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("list-of-vehicles");
  const [open, setOpen] = useState(false);
  const [MotoCheckopen, setMotoCheckOpen] = useState(false);
  const [AddVehicleopen, setAddVehicleopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const navigateToVehicleDetail = (evt) => {
    console.log("navigateToVehicleDetail", evt?.id);
    dispatch(selectVehicle(evt));
    navigate(`/app/vehicles/vehicledetails/${evt?.id}`);
  };

  const navigateToListOfVehicles = (evt) => {
    setOpen(false);
    setAddVehicleopen(false);
    console.log("navigateTo list-of-vehicles");
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
    setMotoCheckOpen(false);
    setAddVehicleopen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const selectActiveComponent = () => {
    if (selectedMenu == "list-of-vehicles") {
      return (
        <VehicleScrollList onSelectVehicleHandler={navigateToVehicleDetail} />
      );
    }

    if (selectedMenu == "add-vehicle") {
      showModal();
    }
  };

  // const addVehicleFormUI = () => {
  //   return (
  //     <Modal
  //       open={open}
  //       title="Add Vehicle"
  //       onOk={handleOk}
  //       onCancel={handleCancel}
  //       footer={false}
  //     >
  //       <Addvehiclesform onAddVehicleHandler={navigateToListOfVehicles} />
  //     </Modal>
  //   );
  // };

  // const MotoCheckModel = () => {
  //   return (
  //     <Modal
  //       open={MotoCheckopen}
  //       title="Moto Check"
  //       onOk={handleOk}
  //       onCancel={handleCancel}
  //       footer={false}
  //     >
  //       <MotoCheck />
  //     </Modal>
  //   );
  // };

  const AddVehicleModel = () => {
    return (
      <Modal
        open={AddVehicleopen}
        title="Add Vehicle"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <AddVehicleWithNZTA onAddVehicleHandler={navigateToListOfVehicles} />
      </Modal>
    );
  };

  return (
    <div>
      {/* {contextHolder} */}
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: 0,
            display: true ? "block" : "none",
            marginBottom: 32,
          }}
        >
          <SubHeader setSelectedMenu={setSelectedMenu} />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: 20,
            }}
          >
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => setAddVehicleopen(true)}
            >
              Add Vehicle
            </Button>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => navigate("/app/bulkupload")}
            >
              Bulk Upload
            </Button>
            {/* <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => setMotoCheckOpen(true)}
            >
              Moto Check
            </Button>
            <Button type="primary" onClick={() => showModal()}>
              Add Vehicle
            </Button> */}
          </div>
        </Header>
        <div
          style={{
            paddingTop: 25,
          }}
        >
          {selectActiveComponent()}
        </div>

        {/* {addVehicleFormUI()}
        {MotoCheckModel()} */}
        {AddVehicleModel()}
      </div>
    </div>
  );
}

export default VehiclesScreen;

