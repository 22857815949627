// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Alert,
  Spin,
  message,
  Divider,
} from "antd";
import { authentication, requestForToken } from "../config/firebaseConfig";
import * as firebase from "firebase/auth";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import {
  checkExistingUser,
  driverLoginToApp,
} from "../store/actions/authActions";
import {
  signInUser,
  updateAuthStatus,
  getNotifications,
} from "../store/reducers/authReducer";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import OperatorOnboardingModal from "./OperatorOnboardingModal";
const { Text, Link, Title } = Typography;

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 6,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function Login(props) {
  const dispatch = useDispatch();
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [userData, setUserData] = useState({});

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //

  const callback = (data, token) => {
    // setLoader(false);
    if (data) {
      const firebasedata = {
        token_id: token,
      };
      props.SignIn(firebasedata);
      navigate("/app/dashboard");
    } else {
    }
  };

  const onclicksignup = () => {
    navigate("/signup");
  };
  const onclickforgotpass = () => {
    navigate("/forgotpassword");
  };
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = (values) => {
    setLoadingSpin(true);
    let errormsg = document.getElementById("Errormsg");
    console.log("Success:", values?.email, values?.password);
    firebase
      .signInWithEmailAndPassword(
        authentication,
        values?.email,
        values?.password,
      )
      .then((res) => {
        console.log("firebase access token", res.user.accessToken);
        res.user
          .getIdToken()
          .then((token) => {
            props.ExistingUser(values?.email, (res) => {
              if (res?.user_exists) {
                if (res?.isOperator) {
                  dispatch(signInUser(token)).then((result) => {
                    // sessionStorage.setItem("token", result?.payload?.data?.token)
                    if (result?.payload?.data?.success) {
                      requestForToken().then((MfcToken) => {
                        const access_token = result?.payload?.data?.token;
                        const payload = { MfcToken, access_token };
                        dispatch(getNotifications(payload));
                      });
                      dispatch(updateAuthStatus(true));
                      setLoadingSpin(false);
                      navigate("/app/dashboard");
                    }
                    if (result?.payload?.data?.success == false) {
                      setLoadingSpin(false);
                      messageApi.open({
                        type: "warning",
                        content: result?.payload?.data?.message,
                        className: "custom-class",
                        style: {
                          marginTop: "10vh",
                        },
                      });
                    }
                  });
                } else if (!res?.isOperator) {
                  setLoadingSpin(false);
                  if (res?.authProvider !== "google.com") {
                    messageApi.open({
                      type: "error",
                      content:
                        "Account with this email already exists in eLog App, Please use same password to register here.",
                      className: "custom-class",
                      style: {
                        marginTop: "10vh",
                      },
                    });
                  } else {
                    messageApi.open({
                      type: "error",
                      content:
                        "Account with this email already exists in eLog App, Please use register/login with googl to register here.",
                      className: "custom-class",
                      style: {
                        marginTop: "10vh",
                      },
                    });
                  }
                }
              } else {
                setLoadingSpin(false);
                messageApi.open({
                  type: "error",
                  content:
                    "The operator associated with this email does not exist.",
                  className: "custom-class",
                  style: {
                    marginTop: "10vh",
                  },
                });
              }
            });
          })
          .catch((err) => {
            messageApi.open({
              type: "error",
              content: err,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
            console.error("failed to get IdToken");
          });

        // checking for existing user should happen when email is
        // already typed! Not before calling our sign-in/create account API
        // props.ExistingUser(values.email, callback, res.user.accessToken);
      })
      .catch((err) => {
        setLoadingSpin(false);
        errormsg.innerText = "Incorrect username or password";
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const loginWithGoogle = (credential) => {
    setLoadingSpin(true);
    console.log(credential);
    const token = credential;
    const user = jwtDecode(token);
    const googleCredential = firebase.GoogleAuthProvider.credential(credential);
    firebase
      .signInWithCredential(authentication, googleCredential)
      .then((res) => {
        const access_token = res?.user?.accessToken;
        props.ExistingUser(user?.email, (res) => {
          if (!(res?.user_exists && res?.isOperator)) {
            setUserData({
              firstname: user?.given_name,
              lastname: user?.family_name,
              phoneNumber: user?.phone_number ? user?.phone_number : "",
              operatorName: user?.business_name ? user?.business_name : "",
              token_id: access_token,
              email: user?.email,
              displayName: user?.name,
              authProvider: res?.providerId,
            });
            setOnboardingModal(true);
            setLoadingSpin(false);
          } else {
            dispatch(signInUser(access_token)).then((result) => {
              if (result?.payload?.data?.success) {
                requestForToken().then((MfcToken) => {
                  const access_token = result?.payload?.data?.token;
                  const payload = { MfcToken, access_token };
                  dispatch(getNotifications(payload));
                });
                dispatch(updateAuthStatus(true));
                setLoadingSpin(false);
                navigate("/app/dashboard");
              } else if (result?.payload) {
                const { message } = JSON.parse(result?.payload);
                setLoadingSpin(false);
                messageApi.open({
                  type: "warning",
                  content: message,
                  className: "custom-class",
                  style: {
                    marginTop: "10vh",
                  },
                });
              }
            });
          }
        });
      })
      .catch((err) => {
        setLoadingSpin(false);
        messageApi.open({
          type: "error",
          content: err,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("failed to get IdToken");
      });
  };

  const success = () => {
    messageApi.open({
      type: "warning",
      content: "This is a prompt message with custom className and style",
      className: "custom-class",
      style: {
        marginTop: "10vh",
      },
    });
  };

  return (
    <Spin spinning={loadingSpin} size="large">
      {contextHolder}
      <OperatorOnboardingModal
        visible={onboardingModal}
        data={userData}
        onClose={() => {
          setUserData({});
          setOnboardingModal(false);
        }}
      />
      <Col style={{ alignItems: "center", marginTop: 100 }}>
        <Title direction="horizontal" level={4}>
          Login
        </Title>
        <Form
          {...layout}
          onSubmit={onSubmit}
          validateMessages={validateMessages}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={styles.text_input}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Enter the valid email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              lg: {
                offset: 9,
              },
              md: {
                offset: 9,
              },
              sm: {
                offset: 9,
              },
            }}
          >
            <p id="Errormsg" style={{ color: "red" }}></p>
            <Button
              type="primary"
              htmlType="submit"
              block
              // style={{ width: 400 }}
            >
              Login
            </Button>
            <Divider>or</Divider>

            <div block style={{ justifyContent: "center", display: "flex" }}>
              <GoogleLogin
                width={"400"}
                theme="outline"
                logo_alignment="center"
                onSuccess={(credentialResponse) => {
                  loginWithGoogle(credentialResponse?.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
          </Form.Item>
        </Form>

        <br />
        <Text direction="horizontal" style={styles.text_input}>
          Don't have account ? <Link onClick={onclicksignup}>Sign up</Link>
        </Text>
        <br></br>
        <Link onClick={onclickforgotpass}>Forgot Password</Link>
      </Col>
    </Spin>
  );
}

const mapStateToProps = (state) => {
  return {
    firebasetoken: state.authReducer?.firebase_token,
    isUserExists: state.authReducer?.isUserExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExistingUser: (payload, callback, token) =>
      dispatch(checkExistingUser(payload, callback, token)),
    // SignIn: (payload, callback) =>
    //   dispatch(driverLoginToApp(payload, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

