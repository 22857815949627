import { Button, Form, Input, Select, message } from "antd";
import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicle,
  addVehicleToList,
  fetchAllVehiclesAPI,
  fetchVehicleMake,
  fetchVehicleModels,
  fetchVehicleTypes,
  findVehicleByPlateNo,
} from "../store/reducers/vehicleReducer";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const MotoCheck = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let accesstoken = useSelector((state) => state?.persist?.auth?.token);
  let token = useSelector((state) => state?.persist?.auth?.token);
  let refreshToken = useSelector((state) => state?.persist?.auth?.refreshToken);
  const [messageApi, contextHolder] = message.useMessage();
  const [motoCheckDone, setMotoCheckDone] = useState(false);

  const [form] = Form.useForm();

  let vehiclesstate = useSelector((state) => state?.persist?.vehicle);

  useEffect(() => {
    dispatch(fetchVehicleMake());
    dispatch(fetchVehicleTypes());
  }, []);

  const onFinishMotoCheck = (values) => {
    const payload = {
      plate: values?.PlateNo,
      accesstoken: accesstoken,
    };
    setMotoCheckDone(true);
    dispatch(findVehicleByPlateNo(payload)).then((response) => {
      if (response?.success) {
        setMotoCheckDone(true);
        form.setFieldsValue({
          Make: response?.make,
          Model: response?.model,
          Type: response?.type,
          PlateNo: response?.plate, //plate number is in special charecter "#plate"
        });
      }
    });
  };

  const CheckNewVehicle = () => {
    onReset();
    setMotoCheckDone(false);
  };

  const onFinish = (values) => {
    const payload = {
      accessToken: accesstoken,
      vehiclemakeID: values?.Make,
      vehiclemodelID: values?.Model,
      vehicletypeID: values?.Type,
      plateNumber: values?.PlateNo,
      initialOdometer: values?.CurrentOdometer,
    };
    dispatch(addVehicle(payload)).then((response) => {
      if (response.payload.data.error !== undefined) {
        messageApi.open({
          type: "warning",
          content: response.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
      if (response.payload.data.message !== undefined) {
        messageApi.open({
          type: "success",
          content: response.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
      if (response.type === "vehicle/addVehicle/fulfilled") {
        dispatch(fetchAllVehiclesAPI(token));
        props.onAddVehicleHandler("list-of-vehicles");
        onReset();
      }
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const makeChanged = (e) => {
    const vehiclemakeID = e;
    dispatch(fetchVehicleModels(vehiclemakeID));
  };

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };
  return (
    <>
      {motoCheckDone ? (
        <>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {contextHolder}
            <Form.Item
              name="PlateNo"
              label="Plate No"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
                {
                  required: false,
                  pattern: new RegExp(/^[a-zA-Z0-9-]{0,14}$/),
                  message: "Enter a valid Plate No",
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Plate No"
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="Make"
              label="Make"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Make"
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="Model"
              label="Model"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Model"
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="Type"
              label="Type"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Type"
                disabled={true}
              />
            </Form.Item>

            <Form.Item
              name="CurrentOdometer"
              label="Current Odometer"
              style={styles.text_input}
              rules={[
                {
                  required: false,
                  pattern: new RegExp(/^[0-9]{0,7}$/),
                  message: "Enter a valid Odometer",
                },
              ]}
            >
              <Input suffix="KM" placeholder="Current Odometer" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                lg: {
                  offset: 8,
                },
                md: {
                  offset: 8,
                },
                sm: {
                  offset: 8,
                },
              }}
            >
              <Button
                id="addVehicleButton"
                type="primary"
                htmlType="submit"
                block
              >
                Add Vehicle
              </Button>
              <Button
                type="default"
                block
                onClick={CheckNewVehicle}
                style={{ marginTop: 10 }}
              >
                Try Different Vehicle
              </Button>
              <p id="success_msg"></p>
            </Form.Item>
            {/* <Model /> */}
          </Form>
        </>
      ) : (
        <>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinishMotoCheck}
            validateMessages={validateMessages}
            // style={styles.text_input}
          >
            {contextHolder}
            <Form.Item
              name="PlateNo"
              label="Plate No"
              style={styles.text_input}
              rules={[
                {
                  required: true,
                },
                {
                  required: false,
                  pattern: new RegExp(/^[a-zA-Z0-9-]{0,14}$/),
                  message: "Enter a valid Plate No",
                },
              ]}
            >
              <Input
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                placeholder="Plate No"
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                lg: {
                  offset: 8,
                },
                md: {
                  offset: 8,
                },
                sm: {
                  offset: 8,
                },
              }}
            >
              <Button
                id="addVehicleButton"
                type="primary"
                htmlType="submit"
                block
              >
                Check Vehicle
              </Button>
              <p id="success_msg"></p>
            </Form.Item>
            {/* <Model /> */}
          </Form>
        </>
      )}
    </>
  );
};
export default MotoCheck;

