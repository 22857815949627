import React, { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Form,
  Select,
  Card,
  Spin,
  message,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteDriverData,
  updateDriverProfile,
  fetchAllDrivers,
} from "../store/reducers/driverReducer";
import {
  fetchAllVehicleLogs,
  fetchAllVehiclesAPI,
  fetchSingelVehicleLogs,
} from "../store/reducers/vehicleReducer";
import moment from "moment";

function VehicleScrollList(props) {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [loadingSpin, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  ///
  // console.log(props)
  const dispatch = useDispatch();
  let token = useSelector((state) => state?.persist?.auth?.token);
  console.log("auth", token);
  let state = useSelector((state) => state);
  console.log("state", state);
  console.log("Active vehicle", state?.persist?.vehicle?.activeVehicle);
  const payload = {
    token: token,
    activeVehicle: state?.persist?.vehicle?.activeVehicle?.id,
  };

  useEffect(() => {
    setLoadingSpinner(true);
    dispatch(fetchAllVehiclesAPI(token)).then((res) => {
      setLoadingSpinner(false);
    });
    dispatch(fetchSingelVehicleLogs(payload));
  }, [dispatch]);

  let fetchAllVehicles = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList,
  );
  console.log("get vehicles", fetchAllVehicles);

  const [IsViolationsModelOpen, setIsViolationsModelOpen] = useState(false);
  const [isModalRowClickDisabled, setIsModalRowClickDisabled] = useState(false);
  const [violationsDataforTabel, setViolationsDataforTabel] = useState("");
  console.log(violationsDataforTabel, "violationsDataforTabel");
  const violationsHandleCancel = () => {
    setIsViolationsModelOpen(false);
    setIsModalRowClickDisabled(false);
  };
  const violationsColumns = [
    {
      title: "Date & Time",
      width: 100,
      key: "date",
      render: (record) => (
        <>
          <Space size="middle">
            {moment(record?.createdAt).format("DD-MM-YYYY hh:mm A")}
          </Space>
        </>
      ),
    },
    {
      title: "Vehicle",
      key: "vehicle",
      render: (record) => (
        <>
          <Space size="middle">{record?.vehicle}</Space>
        </>
      ),
    },
    {
      title: "Message",
      width: 400,
      key: "message",
      render: (record) => (
        <>
          <Space size="middle">
            {record?.businessRuleViolated?.rule?.errorMessage}
          </Space>
        </>
      ),
    },
    {
      title: "Warning",
      key: "warning",
      render: (record) => (
        <>
          <Space size="middle">
            {" "}
            {record?.businessRuleViolated?.rule?.rule_type}
          </Space>
        </>
      ),
    },
  ];
  const columns = [
    {
      title: "Plate No.",
      dataIndex: "plateNumber",
      key: "plateNumber",
      width: "15%",
      render: (plateNumber, vehicle) => (
        <div className="blink">
          <p
            style={{
              fontSize: 14,
              fontWeight: "600",
              letterSpacing: "2px",
              marginBottom: "0px",
              border: "double",
              display: "flex",
              justifyContent: "center",
              borderRadius: "5px",
              backgroundColor: vehicle.isAssigned
                ? vehicle.isActive
                  ? "#05ad48"
                  : "orange"
                : "red",
            }}
          >
            #{plateNumber}
          </p>
        </div>
      ),
    },
    {
      title: "Fleet Code",
      dataIndex: "fleetCode",
      key: "plateNumber",
      width: "10.7%",
    },
    {
      title: "Make",
      dataIndex: "make_nzta",
      key: "make",
      width: "10.3%",
      // render: (item) => Object.values(item)[1],
    },
    {
      title: "Model",
      dataIndex: "model_nzta",
      key: "model",
      width: "10.3%",
      // render: (item) => Object.values(item)[1],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "13.5%",
      // render: (item) => Object.values(item)[1],
    },

    {
      title: "Odometer (KM)",
      dataIndex: "initialOdometer",
      key: "initialOdometer",
      width: "9.7%",
      render: (item) => <Space>{Number(item).toLocaleString()}</Space>,
    },
    {
      title: "Owned by",
      dataIndex: "ownedBy",
      key: "ownedBy",
      width: "9.4%",
      // render: (item) => Object.values(item)[1],
    },
    {
      title: "Assigned Driver",
      dataIndex: "assignedTo",
      key: "assignedTo",
      width: "9.4%",
      render: (item) => <Space>{item ? item : "Un-Assigned"}</Space>,
    },
    {
      title: "Violations",
      dataIndex: "violationCount",
      key: "violationCount",
      width: "9.5%",
      render: (item, record) => (
        <>
          <a onClick={() => console.log("anchor is clicked", record)}>{item}</a>
          <a
            onClick={() => {
              setIsViolationsModelOpen(true);
              setViolationsDataforTabel(record?.violationDetails);
            }}
          >
            {" "}
            View
          </a>
          <Modal
            title="Violation Details"
            style={{ padding: 0 }}
            width={900}
            open={IsViolationsModelOpen}
            onCancel={violationsHandleCancel}
            footer={null}
          >
            <Table
              dataSource={violationsDataforTabel}
              scroll={{
                y: 350,
              }}
              columns={violationsColumns}
              pagination={false}
              size={"middle"}
            ></Table>
          </Modal>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "8%",
      render: (item) => (
        <a onClick={() => props.onSelectVehicleHandler(item)}>View</a>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const onSubmit = (reason_todelete_driver) => {};

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows,
      );
    },
  };
  //
  return (
    <div>
      {contextHolder}
      <Spin spinning={loadingSpin} size="large">
        <Table
          // className="custom-row-hover"
          rowClassName="custom-row-hover"
          dataSource={fetchAllVehicles}
          scroll={{
            y: 430,
          }}
          columns={columns}
          pagination={false}
          style={{ paddingTop: 10 }}
        ></Table>
      </Spin>
    </div>
  );
}

export default VehicleScrollList;

