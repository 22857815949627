import { GoogleLogin } from "@react-oauth/google";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Spin,
  Typography,
  message,
} from "antd";
import * as firebase from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authentication, requestForToken } from "../config/firebaseConfig";
import { checkExistingUser } from "../store/actions/authActions";
import {
  CheckOutlined,
  CloseOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import {
  getNotifications,
  onBoardingOperator,
  signInUser,
  updateAuthStatus,
} from "../store/reducers/authReducer";
import OperatorOnboardingModal from "./OperatorOnboardingModal";

const { Text, Link, Title } = Typography;

const styles = {
  phoneinput: {
    textOverflow: "ellipsis",
    display: "flex",
  },
};

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 6,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function Signup() {
  const [value, setValue] = useState();
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [userData, setUserData] = useState({});

  const [validatedPassword, setValidatedPassword] = useState({
    errorShown: false,
    isLongEnough: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialCharacter: false,
    hasNumber: false,
  });
  const onclicklogin = () => {
    navigate("/login");
  };
  const onclickforgotpass = () => {
    navigate("/forgotpassword");
  };
  const dispatch = useDispatch();
  const styles = {
    phoneinput: {
      textOverflow: "ellipsis",
      display: "flex",
      width: "100%",
    },
    text_input: {
      textAlign: "left",
    },
  };
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loginModal, setLoginModal] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const validatePassword = (value) => {
    setValidatedPassword({
      errorShown: true,
      isLongEnough: value.length >= 6,
      hasUppercase: /[A-Z]/.test(value),
      hasLowercase: /[a-z]/.test(value),
      hasNumber: /\d/.test(value),
      hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>~`/\\;:'"[\]{}\-_=+]/.test(
        value,
      ),
    });
    setPassword(value);
  };

  const onFinish = (values) => {
    if (
      !(
        validatedPassword.isLongEnough &&
        validatedPassword.hasUppercase &&
        validatedPassword.hasNumber &&
        validatedPassword.hasLowercase &&
        validatedPassword.hasSpecialCharacter
      )
    ) {
      setValidatedPassword({
        errorShown: true,
      });
    } else {
      setLoadingSpinner(true);
      firebase
        .createUserWithEmailAndPassword(authentication, values?.email, password)
        .then((res) => {
          const { providerId } = res.user.providerData[0];
          res.user
            .getIdToken()
            .then((token) => {
              const payload = {
                firstname: values.firstname,
                lastname: values.lastname,
                phoneNumber: values.phone_number,
                operatorName: values?.business_name,
                email: values?.email,
                token_id: token,
                authProvider: providerId,
              };
              dispatch(onBoardingOperator(payload)).then((result) => {
                //alert(JSON.stringify(result))
                if (result?.payload?.data?.success === true) {
                  dispatch(signInUser(token)).then((result) => {
                    if (result?.payload?.data?.token) {
                      // sessionStorage.setItem("token", result?.payload?.data?.token);
                      dispatch(updateAuthStatus(true));
                      setLoadingSpinner(false);
                      navigate("/app/dashboard");
                    }
                  });
                }
                if (result?.payload?.data?.success === false) {
                  setLoadingSpinner(false);
                  const errorMessage = result?.payload?.data?.message;
                  messageApi.open({
                    type: "warning",
                    content: errorMessage,
                    className: "custom-class",
                    style: {
                      marginTop: "10vh",
                    },
                  });
                }
              });
            })
            .catch((err) => {
              console.error("failed to get IdToken");
              setLoadingSpinner(false);
            });
        })
        .catch((err) => {
          if (err.code.substring(5) === "email-already-in-use") {
            dispatch(
              checkExistingUser(values?.email, (res) => {
                const { user_exists, isDriver, isOperator, authProvider } = res;
                if (user_exists && isOperator && !isDriver) {
                  setLoadingSpinner(false);
                  setLoginModal(true);
                  messageApi.open({
                    type: "error",
                    content: err.code.substring(5),
                    className: "custom-class",
                    style: {
                      marginTop: "10vh",
                    },
                  });
                } else if (
                  user_exists &&
                  isDriver &&
                  !isOperator &&
                  authProvider == "password"
                ) {
                  loginIfuserExists(values);
                } else if (user_exists && isOperator) {
                  messageApi.open({
                    type: "error",
                    content:
                      "Account with this email already exists, Please login and try again.",
                    className: "custom-class",
                    duration: 5,
                    style: {
                      marginTop: "10vh",
                    },
                  });
                } else {
                  messageApi.open({
                    type: "error",
                    content:
                      "Account with this email already exists in eLog App, Please use signin with google to register here.",
                    className: "custom-class",
                    duration: 5,
                    style: {
                      marginTop: "10vh",
                    },
                  });
                }
              }),
            );
          } else {
            messageApi.open({
              type: "error",
              content: err.code.substring(5),
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          }
          setLoadingSpinner(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loginIfuserExists = (data) => {
    firebase
      .signInWithEmailAndPassword(authentication, data?.email, data?.password)
      .then((res) => {
        res.user.getIdToken().then((token) => {
          const payload = {
            firstname: data?.firstname,
            lastname: data?.lastname,
            phoneNumber: data?.phone_number,
            operatorName: data?.business_name,
            token_id: token,
          };
          dispatch(onBoardingOperator(payload))
            .then((result) => {
              if (result?.payload?.data?.success === true) {
                dispatch(signInUser(token))
                  .then((result) => {
                    setLoadingSpinner(false);
                    if (result?.payload?.data?.token) {
                      dispatch(updateAuthStatus(true));
                      localStorage.removeItem("userData");
                      navigate("/app/dashboard");
                    }
                  })
                  .catch((error) => {
                    setLoadingSpinner(false);
                    console.log("error........", error);
                  });
              } else {
                setLoadingSpinner(false);
                const errorMessage = result?.payload?.data?.message;
                messageApi.open({
                  type: "warning",
                  content: errorMessage,
                  className: "custom-class",
                  style: {
                    marginTop: "10vh",
                  },
                });
              }
            })
            .catch((error) => {
              setLoadingSpinner(false);
              console.log("error........", error);
            });
        });
      })
      .catch((err) => {
        setLoadingSpinner(false);
        const errorMessage = err?.code?.substring(5);
        if (String(errorMessage) == "wrong-password") {
          messageApi.open({
            type: "error",
            content:
              "Account with this email already exists in eLog App, Please use same password to register here.",
            className: "custom-class",
            duration: 5,
            style: {
              marginTop: "10vh",
            },
          });
        } else {
          messageApi.open({
            type: "error",
            content: errorMessage,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
      });
  };

  const checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    console.log(form);
  };

  const onSubmit = (event) => {
    // event.preventDefault();
    // setLoadingSpinner(true);
  };
  const registerWithGoogle = (credential) => {
    setLoadingSpinner(true);
    const googleCredential = firebase.GoogleAuthProvider.credential(credential);
    const user = jwtDecode(credential);
    firebase
      .signInWithCredential(authentication, googleCredential)
      .then((result) => {
        const access_token = result?.user?.accessToken;
        dispatch(
          checkExistingUser(user?.email, (res) => {
            setLoadingSpinner(false);
            if (!(res?.user_exists && res?.isOperator)) {
              setUserData({
                firstname: user?.given_name,
                lastname: user?.family_name,
                phoneNumber: user?.phone_number ? user?.phone_number : "",
                operatorName: user?.business_name ? user?.business_name : "",
                token_id: access_token,
                email: user?.email,
                displayName: user?.name,
                authProvider: result?.providerId,
              });
              setOnboardingModal(true);
            } else {
              dispatch(signInUser(access_token)).then((result) => {
                if (result?.payload?.data?.success) {
                  requestForToken().then((MfcToken) => {
                    const access_token = result?.payload?.data?.token;
                    const payload = { MfcToken, access_token };
                    dispatch(getNotifications(payload));
                  });
                  dispatch(updateAuthStatus(true));
                  setLoadingSpinner(false);
                  navigate("/app/dashboard");
                }
                if (result?.payload?.data?.success == false) {
                  setLoadingSpinner(false);
                  messageApi.open({
                    type: "warning",
                    content: result?.payload?.data?.message,
                    className: "custom-class",
                    style: {
                      marginTop: "10vh",
                    },
                  });
                }
              });
            }
          }),
        );
      })
      .catch((error) => {
        setLoadingSpinner(false);
        console.log("error........", error);
      });
  };
  return (
    <Spin spinning={loadingSpinner} size="large">
      {contextHolder}
      <OperatorOnboardingModal
        visible={onboardingModal}
        data={userData}
        onClose={() => {
          setUserData({});
          setOnboardingModal(false);
        }}
      />
      <Modal
        title="Notifications"
        centered
        open={loginModal}
        onOk={() => {
          setLoginModal(false);
          navigate("/login");
        }}
        onCancel={() => setLoginModal(false)}
        style={{ width: 800 }}
        okText="Login"
      >
        <p style={{ marginBottom: 0 }}>
          Email already in use. Please try log in with username and password.
        </p>
      </Modal>
      <Col>
        <Title
          direction="horizontal"
          style={{ display: "flex", justifyContent: "center", marginTop: 70 }}
          level={4}
        >
          Create an Account
        </Title>
        {/* <Space size="middle">
           <Spin size="large" />
     </Space> */}
        <Form
          name="basic"
          {...layout}
          validateMessages={validateMessages}
          initialValues={{
            remember: true,
          }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={styles.text_input}
        >
          <Form.Item
            name="firstname"
            label="First Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,20}$/),
                message: "Enter a valid First Name",
              },
            ]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
          <Col span={2}></Col>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[
              {
                required: true,
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z. ]{0,20}$/),
                message: "Enter a valid Last Name",
              },
            ]}
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Enter the valid email",
              },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              {
                required: true,
              },
              {
                pattern: new RegExp(/^.{5,13}$/),
                message: "Enter valid Phone Number",
              },
            ]}
          >
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="NZ"
              style={styles.phoneinput}
              value={value}
              onChange={setValue}
              className="ant-input"
            />

            {/* <Input max={10} placeholder="Phone Number" /> */}
          </Form.Item>
          <Form.Item
            name="business_name"
            label="Business Name"
            rules={[
              {
                required: true,
              },
              {
                pattern: new RegExp(/^[a-zA-Z0-9- ]{0,50}$/),
                message: "Enter a valid Business Name",
              },
            ]}
          >
            <Input placeholder="Enter Business Name" />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input
              onChange={(e) => validatePassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                marginTop: 5,
                fontSize: 12,
                paddingLeft: 10,
              }}
            >
              <Text
                style={{
                  color: validatedPassword.errorShown
                    ? validatedPassword.hasUppercase
                      ? "green"
                      : "red"
                    : "black",
                }}
              >
                {" "}
                {validatedPassword.errorShown ? (
                  validatedPassword.hasUppercase ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )
                ) : (
                  "*"
                )}{" "}
                Need at least one CAPITAL letter
              </Text>
              <Text
                style={{
                  color: validatedPassword.errorShown
                    ? validatedPassword.hasLowercase
                      ? "green"
                      : "red"
                    : "black",
                }}
              >
                {" "}
                {validatedPassword.errorShown ? (
                  validatedPassword.hasLowercase ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )
                ) : (
                  "*"
                )}{" "}
                Need at least one small letter
              </Text>
              <Text
                style={{
                  color: validatedPassword.errorShown
                    ? validatedPassword.hasNumber
                      ? "green"
                      : "red"
                    : "black",
                }}
              >
                {" "}
                {validatedPassword.errorShown ? (
                  validatedPassword.hasNumber ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )
                ) : (
                  "*"
                )}{" "}
                Need at least one Number (0-9)
              </Text>
              <Text
                style={{
                  color: validatedPassword.errorShown
                    ? validatedPassword.hasSpecialCharacter
                      ? "green"
                      : "red"
                    : "black",
                }}
              >
                {" "}
                {validatedPassword.errorShown ? (
                  validatedPassword.hasSpecialCharacter ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )
                ) : (
                  "*"
                )}{" "}
                Need at least one Symbol
              </Text>
              <Text
                style={{
                  color: validatedPassword.errorShown
                    ? validatedPassword.isLongEnough
                      ? "green"
                      : "red"
                    : "black",
                }}
              >
                {" "}
                {validatedPassword.errorShown ? (
                  validatedPassword.isLongEnough ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )
                ) : (
                  "*"
                )}{" "}
                Minimum 6 characters long
              </Text>
            </div>
          </Form.Item>
          {/* <Form.Item  <CheckOutlined /> <CloseOutlined />
            name="confirmpassword"
            label="Confirm Password"
            hasFeedback
            rules={[
              {
                required: true,
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password does not match"));
                },
              }),
            ]}
          >
            <Input placeholder="Confirm Password" type="password" />
          </Form.Item> */}
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              lg: {
                offset: 9,
              },
              md: {
                offset: 9,
              },
              sm: {
                offset: 9,
              },
            }}
          >
            <Button type="primary" htmlType="submit" block>
              Register
            </Button>
            <Divider>or</Divider>
            <div block style={{ justifyContent: "center", display: "flex" }}>
              <GoogleLogin
                text="signup_with"
                width={"400"}
                onSuccess={(credentialResponse) => {
                  registerWithGoogle(credentialResponse.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
          </Form.Item>
        </Form>

        <Text direction="horizontal">
          Already have account ? <Link onClick={onclicklogin}>Login</Link>
        </Text>
        <br />
        <Link onClick={onclickforgotpass}>Forgot Password</Link>
      </Col>
    </Spin>
  );
}

export default Signup;

