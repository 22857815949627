// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Col, Typography, message } from "antd";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const { Text, Link, Title } = Typography;

function Forgotpassword(values) {
  const [messageApi, contextHolder] = message.useMessage();
  const onclicklogin = () => {
    navigate("/login");
  };
  const onclicksignup = () => {
    navigate("/signup");
  };
  const styles = {
    text_input: {
      textAlign: "left",
      marginTop: "15px",
    },
  };
  const layout = {
    labelCol: {
      span: 9,
    },
    wrapperCol: {
      span: 6,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    let errormsg = document.getElementById("Errormsg");
    console.log("values:", values);
    const auth = getAuth();
    sendPasswordResetEmail(auth, values?.email)
      .then(() => {
        console.log("Email:", values?.email);
        messageApi.open({
          type: "success",
          content: "The reset password link has been sent to your email",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        errormsg.innerText = errorCode.slice(5);
        console.log("errorCode:", errorCode);
        console.log("errorMessage:", errorMessage);
      });
  };

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };

  const onSubmit = (event) => {
    event.preventDefault();
    // setLoading(true);

    console.log("submit!");

    setTimeout(() => {
      // setLoading(false);
      navigate("/app/dashboard");
    }, 1500);
  };

  return (
    <Col style={{ marginTop: 100 }}>
      {contextHolder}
      <Title direction="horizontal" level={4}>
        Forgot Password
      </Title>
      <Text style={styles.text_size}>
        We will send you the password reset link to registered email.
      </Text>
      <Form
        {...layout}
        validateMessages={validateMessages}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={styles.text_input}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
            },
            {
              type: "email",
              message: "Enter the valid email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            lg: {
              offset: 9,
            },
            md: {
              offset: 9,
            },
            sm: {
              offset: 9,
            },
          }}
          style={{ marginTop: -15 }}
        >
          <p id="Errormsg" style={{ color: "red" }}></p>
          <Button type="primary" htmlType="submit" block>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <Text>
        Already have an account ? <Link onClick={onclicklogin}>Login</Link>
      </Text>
      <br></br>
      <Link onClick={onclicksignup}>Create an Account</Link>
    </Col>
  );
}

export default Forgotpassword;

