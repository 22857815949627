import { useDispatch, useSelector } from "react-redux";
import { renewAccessToken } from "../store/reducers/authReducer";
import API from "../store/utils/APIUtils";
// import FormData from "form-data";

// const dispatch = useDispatch();
// let refreshToken = useSelector((state) => state?.persist?.auth?.refreshToken);
// console.log("refreshToken", refreshToken);

export const addVehicleAPIHelper = async (payload) => {
  if (payload) {
    const response = await API.post(
      `/api/v1/operator/vehicles/`,
      {
        vehiclemakeID: payload.vehiclemakeID,
        vehiclemodelID: payload.vehiclemodelID,
        vehicletypeID: payload.vehicletypeID,
        plateNumber: payload.plateNumber,
        initialOdometer: payload.initialOdometer,
      },
      {
        headers: {
          Authorization: `token ${payload.accessToken}`, //bussiness_name
          "Content-Type": "application/json",
        },
      },
    );
    return response;
  }
};

export const addVehicleWithNZTAAPIHelper = async (payload) => {
  if (payload) {
    const response = await API.post(
      `/api/v1/operator/vehicles/`,
      {
        vehicleMakeNZTA: payload.vehicleMakeNZTA,
        vehicleModelNZTA: payload.vehicleModelNZTA,
        vehicletypeID: payload.vehicletypeID,
        plateNumber: payload.plateNumber,
        initialOdometer: payload.initialOdometer,
        fleetCode: payload.fleetCode,
      },
      {
        headers: {
          Authorization: `token ${payload.accessToken}`,
          "Content-Type": "application/json",
        },
      },
    );
    return response;
  }
};

export const fetchVehicleDetailsNZTAAPIHelper = async (payload) => {
  if (payload) {
    const response = await API.get(
      `v1/api/operator/find/vehicle/?model=${payload.model}`,
      {
        headers: {
          Authorization: `token ${payload.accesstoken}`,
          "Content-Type": "application/json",
        },
      },
    );
    return response;
  }
};

export const updateVehicleAPIHelper = async (payload) => {
  if (payload) {
    const response = await API.put(
      `/api/v1/operator/update/vehicle/${payload?.activeVehicle}/`,
      {
        vehiclemakeID: payload.vehiclemakeID,
        vehiclemodelID: payload.vehiclemodelID,
        vehicletypeID: payload.vehicletypeID,
        plateNumber: payload.plateNumber,
        odometer: payload.odometer,
      },
      {
        headers: {
          Authorization: `token ${payload.accessToken}`, //bussiness_name
          "Content-Type": "application/json",
        },
      },
    );
    return response;
  }
};

export const deleteVehicleAPIHelper = async (payload) => {
  try {
    const response = await API.delete(
      `/api/v1/operator/delete/vehicle/${payload?.activeVehicleID}/`,
      {
        headers: {
          Authorization: `token ${payload?.accessToken}`,
          "Content-Type": "application/json",
        },
      },
    );
    // if (response.status === 403) {
    //   dispatch(renewAccessToken(refreshToken))
    // }

    return response;
  } catch (error) {
    if (error?.response) {
      return error?.response?.data;
    } else if (error?.request) {
      return error?.request?.data;
    }
  }
};

export const fetchAllVehiclesAPIHelper = async (token) => {
  const response = await API.get(
    `/api/v1/operator/vehicles/`,
    // {
    //   token_id: token,
    // },
    {
      headers: {
        Authorization: `token ${token}`, //bussiness_name
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const fetchVehicleMakeAPIHelper = async () => {
  const response = await API.get(`/api/v1/vehicle-make/`, {
    headers: {
      // Authorization: "token " + accessToken,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchVehicleModelAPIHelper = async (vehiclemakeID) => {
  const response = await API.get(`/api/v1/${vehiclemakeID}/vehicle-model/`, {
    headers: {
      // Authorization: "token " + accessToken,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchVehicleTypeAPIHelper = async () => {
  const response = await API.get(`/api/v1/vehicle-type/`, {
    headers: {
      // Authorization: "token " + accessToken,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchAllVehicleLogsAPIHelper = async (token) => {
  const response = await API.get(`/api/v1/operator/vehicles/logEntries/`, {
    headers: {
      Authorization: `token ${token}`,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchSingelVehicleLogsAPIHelper = async (payload) => {
  const response = await API.get(
    `/api/v1/operator/vehicle/logs/${payload.activeVehicle}/`,
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const assignVehicleToDriverAPIHelper = async (payload) => {
  const response = await API.post(
    `/api/v1/operator/assign/${payload.driverid}/vehicle/${payload.vehicleid}/`,
    {},
    {
      headers: {
        Authorization: `token ${payload.token}`,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const findVehicleByPlateNoAPIHelper = async (payload) => {
  const response = await API.get(
    `/api/v1/motocheck/`,
    {
      plate: payload?.plate,
    },
    {
      headers: {
        Authorization: `token ${payload.accesstoken}`,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};
export const handleVehicleBulkUpload = async (payload) => {
  try {
    let formData = new FormData();
    formData.append("vehicles_data_file", payload.file);
    const response = await API.post(
      `/api/v1/operator-vehicles-bulk-upload/`,
      formData,
      {
        headers: {
          Authorization: `token ${payload.accesstoken}`,
          "Content-Type": "multipart/form-data-form-data",
        },
      },
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

