import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  InputNumber,
  Spin,
  Space,
  message,
} from "antd";
import { authentication } from "../config/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import {
  onBoardingOperator,
  updateAuthStatus,
} from "../store/reducers/authReducer";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { signInUser } from "../store/reducers/authReducer";
import { fetchAddedTeamUserDetails } from "../store/reducers/operatorReducer";

const { Text, Link, Title } = Typography;

const styles = {
  phoneinput: {},
};

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 6,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function TeamUserSignup() {
  const {
    params: { id },
  } = useMatch("/teamUser/signup/:id");
  const [value, setValue] = useState();

  useEffect(() => {
    dispatch(fetchAddedTeamUserDetails(id)).then((response) => {
      if (response?.payload.success === true) {
        form.setFieldsValue({ email: response?.payload?.data?.email });
      }
    });
  }, []);

  const onclicklogin = () => {
    navigate("/login");
  };
  const onclickforgotpass = () => {
    navigate("/forgotpassword");
  };
  const dispatch = useDispatch();
  const styles = {
    text_input: {
      textAlign: "left",
    },
    // phoneinput: { border: 0 },
  };
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values.email);
    setLoadingSpinner(true);
    createUserWithEmailAndPassword(
      authentication,
      values.email,
      values.password,
    )
      .then((res) => {
        console.log(res.user.accessToken);
        res.user
          .getIdToken()
          .then((token) => {
            dispatch(signInUser(token)).then((result) => {
              if (result.payload.data.token) {
                dispatch(updateAuthStatus(true));
                setLoadingSpinner(false);
                navigate("/app/dashboard");
              }
            });
          })
          .catch((err) => console.error("failed to get IdToken"));
        setLoadingSpinner(false);
      })
      .catch((err) => {
        setLoadingSpinner(false);
        messageApi.open({
          type: "warning",
          content: err.code.substring(5),
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        setLoadingSpinner(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    console.log(form);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoadingSpinner(true);
  };

  return (
    <Spin spinning={loadingSpinner} size="large">
      {contextHolder}
      <Col>
        <Title
          direction="horizontal"
          style={{ display: "flex", justifyContent: "center", marginTop: 70 }}
          level={4}
        >
          Team User Sign up
        </Title>
        <Form
          form={form}
          name="basic"
          {...layout}
          validateMessages={validateMessages}
          initialValues={{
            remember: true,
          }}
          onSubmit={onSubmit}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={styles.text_input}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Enter the valid email",
              },
            ]}
          >
            <Input disabled={true} placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item
            name="confirmpassword"
            label="Confirm Password"
            hasFeedback
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password does not match"));
                },
              }),
            ]}
          >
            <Input placeholder="Confirm Password" type="password" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              lg: {
                offset: 9,
              },
              md: {
                offset: 9,
              },
              sm: {
                offset: 9,
              },
            }}
          >
            <Button type="primary" htmlType="submit" block>
              Register
            </Button>
          </Form.Item>
        </Form>
        <Text direction="horizontal">
          Already have account ? <Link onClick={onclicklogin}>Login</Link>
        </Text>
        <br />
        <Link onClick={onclickforgotpass}>Forgot Password</Link>
      </Col>
    </Spin>
  );
}

export default TeamUserSignup;

