import {
  Avatar,
  Card,
  Modal,
  Result,
  Button,
  Row,
  Divider,
  message,
  Spin,
  Table,
  Switch,
  Col,
} from "antd";
import {
  CheckOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createBillingAddress,
  getAllSubcriptionPlans,
  getBillingAddress,
  onPaymentSuccess,
  paymentStatus,
  preCheckout,
} from "../store/reducers/paymentReducer";
import { Helmet } from "react-helmet";
// import success from "../src/assets/images/successful.gif";
import success from "../assets/images/successful.gif";
import failed from "../assets/images/failed-red.gif";
import moment from "moment";

const OnPaymentSuccess = ({ match, location }) => {
  const [searchParams] = useSearchParams();
  const subId = searchParams.get("sub_id");
  const invoiceId = searchParams.get("invoice_id");
  const ID = searchParams.get("id");
  const Status = searchParams.get("state");

  const dispatch = useDispatch();
  const SubscriptionPlans = useSelector(
    (state) => state?.persist?.payment?.paymentPlans,
  );
  const token = useSelector((state) => state?.persist?.auth?.token);
  const paymentDetails = useSelector(
    (state) => state?.persist?.payment?.paymentDetails,
  );
  const [paymentStatusIsUpdated, setPaymentStatusIsUpdated] = useState(null);
  const [loadingSpin, setLoadingSpin] = useState(false);

  useEffect(() => {
    const payload = {
      invoice_id: invoiceId,
      token: token,
    };
    setLoadingSpin(true);
    setTimeout(() => {
      dispatch(paymentStatus(payload)).then((response) => {
        setPaymentStatusIsUpdated("API is called");
        setLoadingSpin(false);
      });
    }, 10000);
  }, []);

  const navigate = useNavigate();
  const backToPrev = () => {
    navigate(`/app/dashboard`);
  };
  const handleSwitchClick = () => {
    if (planPeriodYearly === false) {
      setPlanPeriodYearly(true);
    }
    if (planPeriodYearly === true) {
      setPlanPeriodYearly(false);
    }
  };
  const [planPeriodYearly, setPlanPeriodYearly] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  //   const { sub_id, invoice_id, state } = Object.fromEntries(
  //     new URLSearchParams(location.search),
  //   );
  //   console.log("From URL!!!", sub_id, invoice_id, state);

  return (
    <div>
      <ArrowLeftOutlined
        style={{
          width: 60,
          fontSize: 20,
          paddingTop: 30,
          paddingLeft: 30,
          display: "flex",
          alignItems: "flex-start",
        }}
        onClick={() => backToPrev()}
      />
      <Helmet>
        <script
          src="https://js.chargebee.com/v2/chargebee.js"
          data-cb-site="elogbook-test"
        ></script>
      </Helmet>

      <Col
        style={{
          display: "flex-row",
          justifyContent: "center",
          marginTop: "10px",
        }}
      ></Col>
      <Col
        style={{
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Spin spinning={loadingSpin} size="large">
            {paymentStatusIsUpdated !== null ? (
              paymentDetails?.data?.status == "success" ? (
                <Card
                  // title="Payment Details"
                  // extra={<a href="#">More</a>}
                  headStyle={{ fontSize: "16px" }}
                  style={{
                    width: 500,
                  }}
                >
                  <Col style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <img
                      src={success}
                      width={200}
                      height={200}
                      style={{ marginTop: -20 }}
                    />
                    <h2 style={{ color: "green" }}>Payment Successful!</h2>

                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Date:</p>
                      <p>
                        {moment(paymentDetails?.data?.createdAt).format(
                          "DD-MMM-YY hh:mm A",
                        )}
                      </p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Plan:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {paymentDetails?.data?.plan}
                      </p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Total:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {paymentDetails?.data?.cost}{" "}
                        {paymentDetails?.data?.currency}
                      </p>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Payment type:</p>
                      <p>{paymentDetails?.data?.paymentMethod}</p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Invoice id:</p>
                      <p>{paymentDetails?.data?.transactionId}</p>
                    </Row>
                  </Col>
                  <Button
                    size="middle"
                    type="primary"
                    style={{ marginBottom: "15px", marginLeft: 15 }}
                    onClick={() => navigate("/app/dashboard")}
                  >
                    Go to Dashboard
                  </Button>
                </Card>
              ) : (
                <Card
                  // title="Payment Details"
                  // extra={<a href="#">More</a>}
                  headStyle={{ fontSize: "16px" }}
                  style={{
                    width: 500,
                  }}
                >
                  <Col style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <img
                      src={failed}
                      width={80}
                      height={80}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    />
                    <h2 style={{ color: "red" }}>Payment Failed!</h2>

                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Date:</p>
                      <p>
                        {moment(paymentDetails?.data?.createdAt).format(
                          "DD-MMM-YY hh:mm A",
                        )}
                      </p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Plan:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {paymentDetails?.data?.plan}
                      </p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Total:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {paymentDetails?.data?.cost}{" "}
                        {paymentDetails?.data?.currency}
                      </p>
                    </Row>

                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Payment type:</p>
                      <p>{paymentDetails?.data?.paymentMethod}</p>
                    </Row>
                    <Row style={{ justifyContent: "space-between" }}>
                      <p>Invoice id:</p>
                      <p>{paymentDetails?.data?.transactionId}</p>
                    </Row>
                  </Col>

                  <Button
                    size="middle"
                    style={{ marginBottom: "15px" }}
                    onClick={() => navigate("/app/dashboard")}
                  >
                    Go to Dashboard
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    style={{ marginBottom: "15px", marginLeft: 15 }}
                    onClick={() => navigate("/app/dashboard/subscribe")}
                  >
                    Retry
                  </Button>
                </Card>
              )
            ) : null}
          </Spin>
        </Row>
      </Col>
    </div>
  );
};

export default OnPaymentSuccess;

