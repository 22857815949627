import { Card, Col, Modal } from "antd";
import { Spin, message } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Row } from "antd";
import {
  fetchSingelDriverLogData,
  fetchSingelDriverLogs,
  filtrDateForLog,
  setPDFReportLink,
} from "../store/reducers/driverReducer";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import Driverdetails from "../components/driverdetails";
import moment from "moment";
import { CSVLink } from "react-csv";

const { Meta } = Card;

const cardStyle = {
  width: 180,
  height: 100,
};
const ViolationcardStyle = {
  width: 180,
  height: 138,
};

const TopcardStyle = {
  marginRight: 10,
};

const DriverlogHistory = (props) => {
  const navigate = useNavigate();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const { Column, ColumnGroup } = Table;
  const dispatch = useDispatch();

  const [isAddDriverModalOpen, setAddDriverModal] = useState();
  const closeDriverModal = () => {
    setAddDriverModal(false);
  };

  const driverData = useSelector(
    (state) => state?.persist?.driver?.singedriverlogs?.data,
  );

  const cumulativeLogs = useSelector(
    (state) => state?.persist?.driver?.singedriverlogs?.data?.cumulativeLogs,
  );

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let activeDriverData = useSelector(
    (state) => state?.persist?.vehicle?.activeDriver,
  );

  useEffect(() => {
    if (access_token) {
      let payload = {
        token: access_token,
        id: props.Id,
      };
      setLoadingSpinner(true);
      dispatch(fetchSingelDriverLogs(payload)).then((res) => {
        setLoadingSpinner(false);
        if (!res.payload.data?.success) {
          if (res.payload.data.error) {
            messageApi.open({
              type: "warning",
              content: res.payload.data.error,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          } else if (res.payload.data.message) {
            messageApi.open({
              type: "warning",
              content: res.payload.data.message,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          }
        }
      });
    }
  }, [access_token]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search Date`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  let activeDriver = [];
  const sortedActiveDriver = activeDriver.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

  console.log(sortedActiveDriver, "sortedActiveDriver");
  const todaysdate = new Date().toLocaleString();

  const headers = [
    { label: "Date", key: "createdAt" },
    { label: "Driver Name", key: "name" },
    { label: "Work Done In Day (hh:mm)", key: "workdone" },
    { label: "Work Remaining In Day (hh:mm)", key: "workremaining" },
    { label: "Rest Taken In Day (hh:mm)", key: "resttaken" },
    { label: "Report", key: "link" },
  ];

  useEffect(() => {
    let payload = {
      token: access_token,
      id: props.Id,
    };
    if (props.Id !== "") {
      dispatch(fetchSingelDriverLogs(payload));
    }
  }, []);

  let singleDriverlog = useSelector(
    (state) => state?.persist?.drivers?.singedriverlogs,
  );

  if (driverData) {
    driverData?.cumulativeLogs?.map((driverItem, index) => {
      activeDriver.push({
        createdAt: moment(driverItem?.createdAt).format("DD-MMM-YY"),
        driverId: driverItem?.driver?.id,
        id: index + 1,
        data: driverItem,
        restInDay: driverItem.restInDay,
        restInPeriod: driverItem.restInPeriod,
        workDoneInDay: driverItem?.workDoneInDay,
        workDoneInPeriod: driverItem?.workDoneInPeriod,
        workRemainingInDay: driverItem?.workRemainingInDay,
        workRemainingInPeriod: driverItem?.workRemainingInPeriod,
      });
    });
  }

  const viewDetailLogs = async (data) => {
    let payload = {
      driverid: props.Id,
      logid: data?.data?.id,
      token: access_token,
    };

    await dispatch(setPDFReportLink(data?.data?.report));
    await dispatch(
      filtrDateForLog(moment(data?.data?.createdAt).format("DD-MM-YY")),
    );
    // await dispatch(filtrDateForLog(data?.createdAt));
    await dispatch(fetchSingelDriverLogData(payload));

    navigate(`/app/drivers/driver/details/${data?.id}`);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      align: "center",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "Work Remaining In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {record?.workRemainingInDay
            ? (record?.workRemainingInDay?.hours < 10
                ? "0" + record?.workRemainingInDay?.hours
                : record?.workRemainingInDay?.hours) +
              ":" +
              (record?.workRemainingInDay?.minutes < 10
                ? "0" + record?.workRemainingInDay?.minutes
                : record?.workRemainingInDay?.minutes)
            : "00:00"}
        </Space>
      ),
    },
    {
      title: "Work Done In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {record?.workDoneInDay
            ? (record?.workDoneInDay?.hours < 10
                ? "0" + record?.workDoneInDay?.hours
                : record?.workDoneInDay?.hours) +
              ":" +
              (record?.workDoneInDay?.minutes < 10
                ? "0" + record?.workDoneInDay?.minutes
                : record?.workDoneInDay?.minutes)
            : "00:00"}
        </Space>
      ),
    },
    {
      title: "Rest In Day (hh:mm)",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {(record.restInDay
            ? record.restInDay.hours < 10
              ? "0" + record.restInDay.hours
              : record.restInDay.hours
            : "00") +
            ":" +
            (record.restInDay.minutes
              ? record.restInDay.minutes < 10
                ? "0" + record.restInDay.minutes
                : record.restInDay.minutes
              : "00")}
        </Space>
      ),
    },
    {
      title: "PDF Logs",
      key: "operation",
      width: 90,
      align: "center",
      render: (text, record) => (
        <a href={record?.data?.report} target={"_blank"}>
          {"Pdf Link"}
        </a>
      ),
    },

    {
      title: "View Logs",
      key: "operation",
      align: "center",
      width: 90,
      render: (_, record) => (
        // dataSource.length >= 1 ? (
        <a
          onClick={(e) => {
            viewDetailLogs(record);
          }}
        >
          View
        </a>
      ),
      // ) : null,
    },
  ];

  const backToPrev = () => {
    navigate(`/app/drivers`);
  };

  return (
    <Spin spinning={loadingSpinner} size="large">
      {contextHolder}
      <div>
        <Modal
          open={isAddDriverModalOpen}
          width={300}
          bodyStyle={{ padding: 0 }}
          onCancel={closeDriverModal}
          footer={false}
        >
          <Driverdetails activeDriverData={activeDriverData} />
        </Modal>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 12,
          }}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <ArrowLeftOutlined
            style={{
              width: 60,
              fontSize: 20,
              marginTop: 20,
              marginBottom: 20,
              paddingRight: 10,
            }}
            onClick={() => backToPrev()}
          />
          <h2
            onClick={() => setAddDriverModal(true)}
            style={{ marginTop: 10, cursor: "pointer", fontWeight: "bold" }}
          >
            {activeDriverData?.name}
          </h2>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 12,
          }}
          style={{
            marginBottom: 20,
            padding: 2,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col>
            <h3>Cumulative Week</h3>
            <Row>
              <Card
                title="Work"
                style={TopcardStyle}
                bodyStyle={{ padding: 0 }}
                headStyle={{ backgroundColor: "#0acf83" }}
                type="inner"
                size="small"
              >
                <Row>
                  <Card
                    title="Total (hh:mm:ss)"
                    style={cardStyle}
                    type="inner"
                    size="small"
                  >
                    <div>
                      {driverData?.summary?.data
                        ? driverData?.summary?.data?.CumulativeDriveTime
                        : "00:00:00"}
                    </div>
                  </Card>
                  <Card
                    title="Remaining (hh:mm:ss)"
                    type="inner"
                    size="small"
                    style={cardStyle}
                  >
                    <div>
                      {driverData?.summary?.data
                        ? driverData?.summary?.data?.TotalRemainderWorkhours
                        : "00:00:00"}
                    </div>
                  </Card>
                </Row>
              </Card>
              <Card
                title="Rest"
                style={TopcardStyle}
                headStyle={{ backgroundColor: "#4facf7" }}
                bodyStyle={{ padding: 0 }}
                type="inner"
                size="small"
              >
                <Row>
                  <Card
                    title="Total (hh:mm:ss)"
                    type="inner"
                    size="small"
                    style={cardStyle}
                  >
                    {driverData?.summary?.data
                      ? driverData?.summary?.data?.CumulativeRestTime
                      : "00:00:00"}
                  </Card>
                  {/* <Card
                    title="Remaining (hh:mm:ss)"
                    type="inner"
                    size="small"
                    style={cardStyle}
                  >
                    {driverData?.summary?.data
                      ? driverData?.summary?.data?.TotalRemainderResthours
                      : "00:00:00"}
                  </Card> */}
                </Row>
              </Card>
              <Card
                title="Violations"
                type="inner"
                size="small"
                headStyle={{ backgroundColor: "#ff6700" }}
                style={ViolationcardStyle}
              >
                <div style={{ paddingTop: 20 }}>{"0"}</div>
              </Card>
              <Button style={{ marginLeft: 20, alignSelf: "flex-end" }}>
                {" "}
                <CSVLink
                  data={sortedActiveDriver.map((item) => ({
                    createdAt: item.createdAt,
                    name: item.data.driver.name,
                    workdone: item.data.workDoneInDay.hours
                      ? `${item.data.workDoneInDay.hours}:${item.data.workDoneInDay.minutes}`
                      : "00:00",
                    workremaining: item.data.workRemainingInDay.hours
                      ? `${item.data.workRemainingInDay.hours}:${item.data.workRemainingInDay.minutes}`
                      : "00:00",
                    resttaken: item.data.restInDay.hours
                      ? `${item.data.restInDay.hours}:${item.data.restInDay.minutes}`
                      : "00:00",
                    link: ` ${item.data.report}`,
                  }))}
                  headers={headers}
                  filename={`${activeDriverData?.name}_Working_hours(${todaysdate}).csv`}
                  target="_blank"
                >
                  Export Table
                </CSVLink>
              </Button>
            </Row>
          </Col>
        </Row>

        <Row span={12}></Row>
        <Row span={12}>
          <Table
            columns={columns}
            dataSource={activeDriver.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            )}
            bordered
            size="middle"
            scroll={{ x: "calc(600px + 50%)", y: 240 }}
            pagination={false}
          />
        </Row>
      </div>
    </Spin>
  );
};

export default DriverlogHistory;

