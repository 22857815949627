import { Button, Card, Col, Row, Select, Space } from "antd";
import { useMatch, useNavigate } from "react-router-dom";
import Vehicledetails from "../components/vehicledetails";
import Vehicleloglist from "../components/vehicleloglist";
import {
  fetchAllVehiclesAPI,
  fetchDateofSingleVehicleLog,
} from "../store/reducers/vehicleReducer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ElogMapComponent } from "../components/googleMapsAPI";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";
import stopFlag from "../assets/images/red-flag.png";
import startFlag from "../assets/images/finish-point.png";

function Singlevehicledetails() {
  const {
    params: { id },
  } = useMatch("/app/vehicles/vehicledetails/:id");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const stopimage =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [PDFLink, setPDFLink] = useState("true");
  const [DisablePDFLink, setDisablePDFLink] = useState(true);
  const vehicleStartLocations = [];
  const vehicleStopLocations = [];
  const pathCoordinates = [];
  let SingleVehicleLogs = useSelector(
    (state) => state?.persist?.vehicle?.SingleVehicleLogs,
  );

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      vehicleStartLocations?.push({
        icon: startFlag,
        latlng: { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        details: {
          detailsType: "Vehicle",
          address: data?.start_coord?.formatted_address,
          driverName: data?.driverName,
        },
      }),
    );
  }

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      vehicleStopLocations?.push({
        icon: stopFlag,
        latlng: { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
        details: {
          detailsType: "Vehicle",
          address: data?.stop_coord?.formatted_address,
          driverName: data?.driverName,
        },
      }),
    );
  }

  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    SingleVehicleLogs?.map((data) =>
      pathCoordinates?.push([
        { lat: data?.start_coord?.lat, lng: data?.start_coord?.lng },
        { lat: data?.stop_coord?.lat, lng: data?.stop_coord?.lng },
      ]),
    );
  }

  const AllDatesforLogs = [];
  if (SingleVehicleLogs !== null && SingleVehicleLogs !== undefined) {
    let map = new Map();

    for (let item of SingleVehicleLogs) {
      if (!map.has(moment(item?.start).format("DD-MM-YYYY"))) {
        map.set(moment(item?.start).format("DD-MM-YYYY"), true);
        AllDatesforLogs?.push({
          value: item?.id,
          label: moment(item?.start).format("DD-MM-YYYY"),
        });
      }
    }
  }

  const handleChange = (data, value) => {
    console.log(value?.label, "date123");

    dispatch(fetchDateofSingleVehicleLog(value));
  };

  const Filter = () => (
    <div>
      <Space wrap>
        <Select
          defaultValue={"Select Date"}
          // showSearch
          style={{
            width: 140,
          }}
          onChange={handleChange}
          options={AllDatesforLogs}
        />
      </Space>
    </div>
  );
  const backToPrev = () => {
    navigate(`/app/vehicles`);
  };
  return (
    <div>
      <Row
        // gutter={{
        //   xs: 8,
        //   sm: 16,
        //   md: 24,
        //   lg: 24,
        // }}
        style={{
          display: "flex",
          marginBottom: 10,
          alignItems: "center",
        }}
      >
        <ArrowLeftOutlined
          style={{ width: 60, fontSize: 20, paddingTop: 20, paddingBottom: 20 }}
          onClick={() => backToPrev(id)}
        />
      </Row>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        style={{
          width: "100%",
          margin: 0,
        }}
      >
        <Col span={12}>
          <Vehicledetails Id={id} />
          <Card
            bodyStyle={{ padding: 0, margin: 0 }}
            title="Logs"
            size="small"
            extra={<Filter />}
          >
            <Vehicleloglist />
          </Card>
        </Col>
        <Col span={12}>
          <ElogMapComponent
            locations={{
              vehicleStartLocations,
              vehicleStopLocations,
              pathCoordinates,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Singlevehicledetails;

