import {
  Avatar,
  Card,
  Modal,
  Result,
  Button,
  Row,
  Divider,
  message,
  Spin,
  Table,
  Col,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  assignVehicleToDriver,
  deleteVehilce,
  fetchAllVehiclesAPI,
  selectDriver,
} from "../store/reducers/vehicleReducer";
import { EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import Editvehicleform from "../pages/editvehicleform";
import DriverList from "../pages/driverlist";

const { Meta } = Card;

const Vehicledetails = ({ Id }) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const activeVehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const EditVehicleFormModel = () => {
    const handleCancel = () => {
      setIsEditModalOpen(false);
    };
    return (
      <>
        <Modal
          title="Edit Vehicle"
          style={{ padding: 0 }}
          open={isEditModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Editvehicleform />
        </Modal>
      </>
    );
  };

  const DeleteModel = () => {
    const handleCancel = () => {
      setIsDeleteModalOpen(false);
    };

    const confirmDelete = () => {
      const payload = {
        accessToken: token,
        activeVehicleID: activevehicle?.id,
      };
      dispatch(deleteVehilce(payload)).then((response) => {
        if (response?.payload?.data?.success === true) {
          dispatch(fetchAllVehiclesAPI(token)).then(() => {
            messageApi.open({
              type: "success",
              content: response?.payload?.data?.message,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
            setIsDeleteModalOpen(false);
            navigate(`/app/vehicles`);
          });
        }
        if (response?.payload?.success === false) {
          if (response?.payload?.message) {
            messageApi.open({
              type: "warning",
              content: response.payload.message,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          } else if (response.payload.error) {
            messageApi.open({
              type: "warning",
              content: response.payload.error,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          }

          setIsDeleteModalOpen(false);
        }
      });
    };

    return (
      <>
        <Modal
          style={{ padding: 0 }}
          open={isDeleteModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Result
            style={{ padding: 0 }}
            status="warning"
            title="Confirm delete?"
            subTitle="Are you sure you want to delete this Vehicle?"
            extra={[
              <Button onClick={confirmDelete} type="danger" key="console">
                Delete
              </Button>,
              <Button onClick={handleCancel}>Cancel</Button>,
            ]}
          />
        </Modal>
      </>
    );
  };
  const dispatch = useDispatch();
  let token = useSelector((state) => state?.persist?.auth?.token);
  let activevehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle,
  );
  let state = useSelector((state) => state);

  const onClickdeleteVehicle = () => {
    showDeleteModal();
  };
  const navigateUpdateVehicle = () => {
    navigate(`/app/vehicles/editvehicle`);
    // showEditModal();
    // props.onSelectVehicleHandler()
  };

  const [DriverListTableOpen, setDriverListTableOpen] = useState(false);
  const assignVehicleToDriver1 = () => {
    setDriverListTableOpen(true);
    // const payload = {
    //   token: token,
    //   vehicleid: state?.persist?.vehicle?.activeVehicle?.id,
    //   driverid: 405,
    // };
    // dispatch(assignVehicleToDriver(payload));
  };

  const DriverListTable = () => {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [loadingSpin, setLoadingSpinner] = useState(false);

    let fetchAllDrivers = useSelector(
      (state) => state?.persist?.driver?.drivers,
    );

    let activeDriver = [];

    if (activeVehicle.type === "Heavy Vehicle") {
      const filteredData = fetchAllDrivers.filter(
        (driver) => driver?.inactive === false && driver?.driverType.id === 1,
      );
      filteredData?.map((driverItem) => activeDriver.push(driverItem));
    } else {
      const filteredData = fetchAllDrivers.filter(
        (driver) => driver?.inactive === false,
      );

      filteredData?.map((driverItem) => activeDriver.push(driverItem));
    }

    const handleOk = () => {
      setDriverListTableOpen(false);
    };

    const handleCancel = () => {
      setDriverListTableOpen(false);
    };
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Phone No.",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "License No.",
        dataIndex: "licenseNumber",
        key: "licenseNumber",
      },
      {
        title: "Type",
        dataIndex: "driverType",
        key: "driverType",
        render: (item) => {
          return <p>{item.name}</p>;
        },
      },
    ];

    return (
      <Modal
        open={DriverListTableOpen}
        title="Select Driver"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={1000}
      >
        <div>
          {contextHolder}
          <Spin spinning={loadingSpin} size="large">
            <Table
              dataSource={activeDriver}
              scroll={{
                y: 400,
              }}
              size={"middle"}
              columns={columns}
              pagination={false}
              onRow={(record) => ({
                style: { cursor: "pointer" },
                onClick: (event) => {
                  setLoadingSpinner(true);
                  console.log("onRow onClick", record);
                  const payload = {
                    token: token,
                    vehicleid: state?.persist?.vehicle?.activeVehicle?.id,
                    // driverid: state?.persist?.vehicle?.activeDriver?.id,
                    driverid: record?.id,
                  };
                  // dispatch(selectDriver(record));
                  dispatch(assignVehicleToDriver(payload)).then((result) => {
                    if (result.payload.data.success === true) {
                      setDriverListTableOpen(false);
                      setLoadingSpinner(false);
                      navigate(`/app/vehicles`);
                    }
                    if (result.payload.data.success === false) {
                      messageApi.open({
                        type: "warning",
                        content: result.payload.data.data,
                        className: "custom-class",
                        style: {
                          marginTop: "10vh",
                        },
                      });
                      // setDriverListTableOpen(false);
                      setLoadingSpinner(false);
                    }
                  });
                },
              })}
            ></Table>
          </Spin>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    dispatch(fetchAllVehiclesAPI(token));
  }, []);
  let fetchAllVehicles = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList,
  );

  return (
    <div style={{ marginBottom: 20 }}>
      {contextHolder}
      <Card
        // title="Vehicle Details"
        style={{
          width: "100%",
        }}
        size="small"
        bodyStyle={{ paddingTop: "10px", textAlign: "left" }}
      >
        {fetchAllVehicles?.map((e) => {
          if (e?.id == Id)
            return (
              <Row>
                <img
                  style={{
                    width: 140,
                    height: 200,
                    paddingBottom: "10px",
                  }}
                  alt="VehicleDetails"
                  src="https://www.mercedes-benz-trucks.com/content/dam/mbo/markets/hq_HQ/model-navigation-images/en_id/actros-distribution-IND.png"
                />
                <Divider type="vertical" style={{ height: 200 }} />
                <Col style={{ paddingLeft: 10 }}>
                  <Col>
                    <p style={{ fontSize: 10, marginBottom: 0 }}>Plate</p>
                    <p
                      style={{
                        // fontSize: 18,
                        fontWeight: "600",
                        letterSpacing: "2px",
                      }}
                    >
                      #{e?.plateNumber}
                    </p>
                  </Col>
                  {/* <Divider style={{ marginTop: 10 }} /> */}
                  {/* <img
                  style={{
                    width: 200,
                    paddingBottom: "10px",
                  }}
                  alt="VehicleDetails"
                  src="https://www.mercedes-benz-trucks.com/content/dam/mbo/markets/hq_HQ/model-navigation-images/en_id/actros-distribution-IND.png"
                /> */}
                  {/* <Divider /> */}
                  {/* <Meta style={{ marginBottom: 10 }} title={e?.make} /> */}
                  <Row>
                    <Col>
                      <p style={{ fontSize: 10, marginBottom: 0 }}>Make</p>
                      <p
                        style={{
                          lineHeight: 1,
                          fontWeight: 600,
                          paddingRight: 50,
                        }}
                      >
                        {e?.make_nzta}
                      </p>
                    </Col>
                    <Col>
                      <p style={{ fontSize: 10, marginBottom: 0 }}>Model</p>
                      <p style={{ lineHeight: 1, fontWeight: 600 }}>
                        {" "}
                        {e?.model_nzta}
                      </p>
                    </Col>
                  </Row>
                  {activevehicle.assignedTo !== null ? (
                    <>
                      <p>
                        {" "}
                        <UserOutlined /> Assigned to{" "}
                        <span style={{ fontWeight: 600 }}>
                          {activevehicle.assignedTo}
                        </span>
                      </p>
                      {/* <Button
                        onClick={assignVehicleToDriver1}
                        type="primary"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        block
                      >
                        Reassign Driver
                      </Button> */}
                    </>
                  ) : (
                    <Button
                      onClick={assignVehicleToDriver1}
                      type="primary"
                      style={{ marginTop: 10, marginBottom: 10 }}
                      block
                    >
                      Assign a Driver
                    </Button>
                  )}
                  <Row
                    style={{
                      justifyContent: "space-between",
                      width: 260,
                    }}
                  >
                    {/* <Button
                      onClick={navigateUpdateVehicle}
                      type="primary"
                      icon={<EditOutlined />}
                      // style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Edit
                    </Button> */}
                    <Button
                      onClick={onClickdeleteVehicle}
                      type="danger"
                      icon={<DeleteOutlined />}
                      block
                    >
                      Delete
                    </Button>
                  </Row>
                </Col>
              </Row>
            );
        })}

        <DeleteModel />
        <EditVehicleFormModel />
        <DriverListTable />
      </Card>
    </div>
  );
};

export default Vehicledetails;

