import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  message,
  Row,
  Col,
  Popconfirm,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOperatorMembers,
  addOperatorMember,
  editOperatorMember,
  deleteOperatorMember,
} from "../store/reducers/operatorReducer";
import PhoneInput from "react-phone-number-input";

function OperatorMembers() {
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phone_number",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <>
          <a
            onClick={(e) => {
              updateOperatorMember(record);
            }}
            style={{ marginRight: 10 }}
          >
            Edit
          </a>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteOperatorConfirmation(record)}
          >
            <a>Delete</a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const operatorMembers = useSelector(
    (state) => state?.persist?.operator?.operatorMembers?.data,
  );

  const [openAddOperatorModal, setAddOperatorModal] = useState(false);
  const [openOperatorMemberEditModal, setOperatorMemberEditModal] =
    useState(false);
  const [isDeleteDriverModalOpen, setOperatorMemberDeleteModal] =
    useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [operatorMemberId, setOperatorMemberId] = useState();
  const [operatorMemberIdForDelete, setoperatorMemberIdForDelete] = useState();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [value, setValue] = useState();
  const [filterInput, setFilterInput] = useState("");
  const { Search } = Input;
  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  useEffect(() => {
    dispatch(fetchOperatorMembers(access_token)).then((res) => {
      if (res.payload.data.error) {
        setAddOperatorModal(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
    });
  }, []);

  const filterData = () => {
    if (filterInput === "") return operatorMembers;

    if (isNaN(filterInput)) {
      return operatorMembers.filter(({ firstname }) =>
        firstname.toLowerCase().includes(filterInput.toLowerCase()),
      );
    }
  };

  const addTheOperatorMember = () => {
    setAddOperatorModal(true);
    form1.resetFields();
  };

  const closeModal = () => {
    setAddOperatorModal(false);
  };

  const closeEditModal = () => {
    setOperatorMemberEditModal(false);
  };

  const onFinish = (values) => {
    setLoadingSpin(true);
    let payload = {
      phoneNumber: values?.phoneNumber,
      lastname: values?.last_name,
      email: values?.email,
      firstname: values?.first_name,
      access_token: access_token,
    };
    dispatch(addOperatorMember(payload)).then((res) => {
      if (res.payload.data.success) {
        setLoadingSpin(false);
        setAddOperatorModal(false);
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
      if (res.payload.data.error) {
        setLoadingSpin(false);
        setAddOperatorModal(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
    });
  };

  const onFinishEdit = (values) => {
    setLoadingSpin(true);
    const payload = {
      firstname: values?.first_name,
      lastname: values?.last_name,
      phoneNumber: values?.phoneNumber,
      email: values?.email,
      operatorMemberId: operatorMemberId,
      access_token: access_token,
    };
    dispatch(editOperatorMember(payload)).then((res) => {
      if (res.payload.data.success == true) {
        setLoadingSpin(false);
        setOperatorMemberEditModal(false);
        dispatch(fetchOperatorMembers(access_token));
        setAddOperatorModal(false);
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        // dispatch(fetchOperatorMembers(access_token));
      }
      if (res.payload.data.success == false) {
        setLoadingSpin(false);
        setAddOperatorModal(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
    });
  };

  const deleteOperatorConfirmation = (record) => {
    setoperatorMemberIdForDelete(record);
    setOperatorMemberDeleteModal(true);
  };
  const deleteTheOperatorMember = (record) => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch((info) => {});
  };

  const onSubmit = (data) => {
    setLoadingSpin(true);
    const payload = { operatorMemberIdForDelete, access_token, data };
    dispatch(deleteOperatorMember(payload)).then((res) => {
      if (res?.payload?.data?.success) {
        form.resetFields();
        setLoadingSpin(false);
        setOperatorMemberDeleteModal(false);
        dispatch(fetchOperatorMembers(access_token));
        setAddOperatorModal(false);
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
      if (res?.payload?.data?.success === false) {
        setLoadingSpin(false);
        setAddOperatorModal(false);
        messageApi.open({
          type: "warning",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(fetchOperatorMembers(access_token));
      }
    });
  };
  const onReset = () => {
    form1.resetFields();
  };
  const onResetEditForm = () => {
    form.resetFields();
  };
  // const onResetEditForm = () => {
  //   form.resetFields();
  // };
  const updateOperatorMember = (record) => {
    setOperatorMemberId(record.id);
    setOperatorMemberEditModal(true);
    form.setFieldsValue({
      first_name: record.firstname,
      last_name: record.lastname,
      email: record.email,
      phoneNumber: record.phoneNumber,
    });
  };

  const handleCancel = () => {
    setOperatorMemberDeleteModal(false);
  };

  return (
    <div>
      {contextHolder}
      <Spin spinning={loadingSpin} size="large">
        <Row style={{ justifyContent: "space-around", marginTop: "10px" }}>
          <Col span={12}>
            <Search
              placeholder="Search User by name"
              allowClear
              enterButton="Search"
              size="medium"
              onSearch={setFilterInput}
              style={{ width: "50%", marginBottom: "10px", marginRight: "50%" }}
            />
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              style={{ marginLeft: "70%", marginBottom: "5%" }}
              onClick={addTheOperatorMember}
            >
              Add User
            </Button>
          </Col>
        </Row>
        {operatorMembers !== undefined && (
          <Table
            scroll={{
              y: 400,
            }}
            pagination={false}
            columns={columns}
            dataSource={filterData()}
          />
        )}
        <Modal
          title="Add User"
          open={openAddOperatorModal}
          onCancel={closeModal}
          // cancelButtonProps={{ style: { display: "none" } }}
          // okButtonProps={{ style: { display: "none" } }}
          footer={false}
        >
          <Spin spinning={loadingSpin} size="large">
            <Form
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
              }}
              onFinish={onFinish}
              form={form1}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid name",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid name",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^.{5,13}$/),
                    message: "Enter valid Phone Number",
                  },
                ]}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NZ"
                  style={styles.phoneinput}
                  value={value}
                  onChange={setValue}
                  className="ant-input"
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Enter the valid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                // justify="space-around"
                wrapperCol={{
                  ...layout.wrapperCol,
                  lg: {
                    offset: 12,
                  },
                  md: {
                    offset: 8,
                  },
                  sm: {
                    offset: 8,
                  },
                }}
              >
                <Row
                  style={{
                    justifyContent: "flex-end",
                    // backgroundColor: "red",
                  }}
                >
                  <Col span={10}>
                    <Button
                      // htmlType="submit"
                      style={{ marginTop: 10, marginRight: 10 }}
                      onClick={onReset}
                      // block
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: 10,
                        paddingRight: 30,
                        paddingLeft: 30,
                      }}
                      block
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              {/* <Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 10 }}
                  block
                >
                  Add Operator
                </Button>

                <Button
                  htmlType="submit"
                  style={{ marginTop: 10 }}
                  onClick={onReset}
                  block
                >
                  Reset
                </Button>
              </Row> */}
            </Form>
          </Spin>
        </Modal>
        <Modal
          title="Edit User"
          open={openOperatorMemberEditModal}
          onCancel={closeEditModal}
          // cancelButtonProps={{ style: { display: "none" } }}
          // okButtonProps={{ style: { display: "none" } }}
          footer={false}
        >
          <Spin spinning={loadingSpin} size="large">
            <Form
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
              }}
              validateMessages={validateMessages}
              onFinish={onFinishEdit}
              form={form}
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid first name",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid last name",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    // message: "required!",
                  },
                  {
                    pattern: new RegExp(/^.{5,13}$/),
                    message: "Enter valid Phone Number",
                  },
                ]}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NZ"
                  style={styles.phoneinput}
                  value={value}
                  onChange={setValue}
                  className="ant-input"
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Enter the valid email",
                  },
                ]}
              >
                <Input disabled placeholder="Email" />
              </Form.Item>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={11}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: 10 }}
                    block
                  >
                    Update
                  </Button>
                </Col>
                <Col span={11}>
                  <Button
                    style={{ marginTop: 10, marginRight: "1%" }}
                    onClick={onResetEditForm}
                    block
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          open={isDeleteDriverModalOpen}
          onOk={deleteTheOperatorMember}
          onCancel={handleCancel}
        >
          <Typography.Title level={4}>Confirm Delete</Typography.Title>

          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Form.Item
              label="Enter the reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
}
export default OperatorMembers;

